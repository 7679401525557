.swiper-holder {
    z-index: 98;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide {
    width: 100%;
}

.slide-content h1 {
    font-size: 45px;
    line-height: 50px;
    color: white;
    text-align: center;
    margin: 0;
    text-shadow: rgba(0, 0, 0, 0.7) 0px 5px 15px;
}

.slide-content h1:after {
    content: "";
    width: 36px;
    background-color: white;
    height: 2px;
    display: block;
    margin: 16px auto;
    box-shadow: rgba(0, 0, 0, 0.7) 0px 5px 15px;
}

.slide-content p {
    color: white;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    font-style: italic;
    text-align: center;
    margin: 0;
    text-shadow: rgba(0, 0, 0, 0.7) 0px 5px 15px;
}

.button-holder {
    text-align: center;
    display: block;
    padding: 20px;
}

.slide-content a {
    border: 1px solid var(--themetext);
    background-color: var(--themetext);
    cursor: pointer;
    padding: 20px 24px;
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    border-radius: 0;
    text-decoration: none;
    box-shadow: rgba(0, 0, 0, 0.7) 0px 5px 15px;
}

.swiper-button-next, .swiper-button-prev {
    color: var(--themetext);
}

.swiper-button-next {
    right: 50px;
}

.swiper-button-prev {
    left: 50px;
}

@media only screen and (max-width: 820px) {

    .swiper-button-next {
        display: none;
    }

    .swiper-button-prev {
        display: none;
    }

    .slide-content {
        width: 80%;
        margin: 0 auto;

    }

    .slide-content h1 {
        font-size: 30px;

    }

}
  