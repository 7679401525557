.lang-switcher {
  position: fixed;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 50%;
  background-color: var(--main);
  padding: 8px 8px 8px 7px;
  border-radius: 10px 0 0 10px;
  gap: 5px;
}

.lang-switcher button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: .4s ease-in-out;
}

#navbar1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    background-color: var(--main);
    transition: .5s ease-in-out;
    border: 1px solid transparent;
    z-index: 99;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.navbar label{
  padding: 10px 15px;
  border-radius: 10px;
  color: var(--themetext);
  border: 1px solid var(--themetext);
  display: none;
}

.enable-menu {
  max-height: 100% !important;
}

#toggler {
    display: none;
}

.nav-links {
    list-style: none;
    display: flex;
    padding: 0;
    cursor: pointer;
  }
  
  .nav-item a {
    display: inline-block;
    padding: 10px 15px;
    text-decoration: none;
    color: white;
    font-size: 14px;
    font-weight: 500;
    transition: .4s ease-in-out;
    text-transform: uppercase;
  }

  .nav-item a:after {
    display:block;
    content: '';
    border-bottom: solid 3px var(--themetext);  
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
  }
  .nav-item a:hover:after { transform: scaleX(1); }

  .logo-effect {
    position: absolute;
    top: 0;
    bottom: -6px;
    left: -35px;
    content: "";
    z-index: -1;
    transform: skew(-30deg);
    background-color: var(--themetext);
    transition: all 0.5s ease;
    opacity: 1;
    border-radius: 0 0 8px 0;
    width: 27%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .logo-effect2 {
    position: absolute;
    top: 0;
    bottom: -6px;
    right: -35px;
    content: "";
    z-index: -1;
    transform: skew(30deg);
    background-color: var(--themetext);
    transition: all 0.5s ease;
    opacity: 1;
    border-radius: 0 0 0 8px;
    width: 27%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .contact-header {
    position: absolute;
    top: 0;
    right: -35px;
    width: 27%;
    bottom: -6px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .contact-header__holder {
    margin-left: 50px;
  }

  .contact-header__holder p {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
  }

  .contact-header__holder p:first-of-type {
    margin-bottom: 8px;
  }

  .contact-header__holder p svg {
    margin-right: 10px;
  }

  .logo-fluid {
    width: 150px;
    height: auto;
    margin-right: 50px;
  }

  .logo {
    position: absolute;
    top: 0;
    left: -35px;
    width: 27%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    bottom: -6px;
  }

/*MOBIL*/

@media only screen and (max-width: 820px) {
  
  #navbar1 {
      border-bottom: 1px solid var(--themetext);
      background-color: var(--main);
      flex-direction: column;
    }
  
    .toggler-hold {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  
    .nav-links {
      flex-direction: column;
      max-height: 0;
      margin: 0;
      overflow: hidden;
    }
  
    .nav-item {
      text-align: center;
    }
  
    .navbar label {
      display: inline-block;
      margin: 10px;
    }

    .logo {
      width: 60%;
      height: 65px;
      justify-content: center;
      left: -25px;
    }

    .logo-fluid {
      margin: 0;
    }

    .logo-effect {
      width: 60%;
      height: 65px;
    }

    .logo-effect2 {
      display: none;
    }

    .contact-header {
      display: none;
    }

  }